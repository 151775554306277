import { Button, Box, CircularProgress, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TablePagination, TableSortLabel, styled } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { Download, FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import ContactItem from './components/ContactItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses, } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, customersAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { visuallyHidden } from '@mui/utils'
import { hasPermission, showConfirmationToast, convertMoney } from '../../../utils/utils';
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import { MultiSelect } from "react-multi-select-component";
import AddContactModal from './modals/AddContactModal';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

const headCells = [
    {
        id: 'full_name',
        paddingRight: false,
        disablePadding: false,
        label: 'Họ tên',
    },
    {
        id: 'username',
        paddingRight: true,
        disablePadding: false,
        label: 'Số điện thoại',
    },
    {
        id: 'customer_priority',
        paddingRight: true,
        disablePadding: false,
        label: 'Hạng khách hàng',
    },
    {
        id: 'booking_remain',
        paddingRight: true,
        disablePadding: false,
        label: 'Số buổi còn lại',
    },
    {
        id: 'current_service',
        paddingRight: true,
        disablePadding: false,
        label: 'Số dịch vụ đang sử dụng',
    },
    {
        id: 'last_action_time',
        paddingRight: true,
        disablePadding: false,
        label: 'Tương tác gần nhất',
    },
    {
        id: 'last_visit_time',
        paddingRight: true,
        disablePadding: false,
        label: 'Lần cuối khách hàng đến',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property, disable) => (event) => {
        if(!disable)
            onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.paddingRight ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.disableSort)}
                            sx={
                                {
                                    '&.MuiTableSortLabel-root': {
                                        color: 'white',
                                    },
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: 'white',
                                    },
                                    '&.Mui-active': {
                                        color: 'white',
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: 'white !important',
                                    },
                                }
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const Screen = () => {
    const [customers, setCustomers] = useAtom(customersAtom)
    const [token] = useAtom(tokenAtom)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [staffs] = useAtom(staffsAtom)
    const [hasOrder, setHasOrder] = useState(1)
    const [total, setTotal] = useState(0)
    const searchTimeout = useRef(null)
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('booking_remain');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const authUser = useAtomValue(authAtom)
    const toastId = React.useRef(null)
    const [showAddContactModal, setShowAddContactModal] = useState(false)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event.target.value)
        setPage(0)
    }

    const getCustomers = useCallback(() => {
        console.log({ token })
        if (token) {
            setLoading(true)
            try {
                if (searchTimeout.current) {
                    clearTimeout(searchTimeout.current)
                    searchTimeout.current = null
                }

            } catch (error) {
                console.log({ error })
            }

            searchTimeout.current = setTimeout(() => {
                axios.post('/accounts/customers/customer_list_new/', {
                    keyword, order, orderBy, offset: page * rowsPerPage, limit: rowsPerPage,
                    staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                    hasOrder: hasOrder === 1
                }, {
                })
                    .then((response) => {
                        console.log({ response })
                        if (response && response.data) {
                            setCustomers(response.data.customers ?? [])
                            setTotal(response.data.total ?? 0)
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log({ error })
                        setLoading(false)
                    })
            }, 500);
        }
    }, [token, keyword, setCustomers, order, orderBy, page, rowsPerPage, selectedStaffs, hasOrder])

    useEffect(() => {
        getCustomers()
    }, [getCustomers, token])

    useEffect(() => {
        getCustomers()
    }, [getCustomers, keyword])

    const onDownload = () => {
        showConfirmationToast(`Chắc chắn tải về danh sách tất cả khách hàng?`,
            () => {
                setTimeout(() => {
                    toastId.current = toast.loading("Đang tải dữ liệu")
                    axios.post('/accounts/customers/download_customer_list/', {
                        keyword, order, limit: rowsPerPage,
                        staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                        hasOrder: hasOrder === 1
                    }, {
                    })
                        .then((response) => {
                            try {
                                if (response && response.data) {
                                    const customerReports = response.data.map((item) => {
                                        const staffs = []
                                        if (item.staffs) {
                                            if (item.staffs.sale1) {
                                                staffs.push(item.staffs.sale1.full_name)
                                            }
                                            if (item.staffs.sale2) {
                                                staffs.push(item.staffs.sale2.full_name)
                                            }
                                            if (item.staffs.telesale) {
                                                staffs.push(item.staffs.telesale.full_name)
                                            }
                                        }
                                        const staffName = staffs.join(', ')
                                        return ({ 
                                            'id': item.id, 
                                            'full_name': item.full_name, 
                                            'username': item.username,  
                                            'totalPrice': item.totalPrice ?? 0, 
                                            'birthday': item.birthday ? dayjs(item.birthday).format('DD/MM/YYYY') : '', 
                                            'address': item.address,
                                            'email': item.email,
                                            'last_action_time': item.last_action_time ? dayjs(item.last_action_time).format('HH:mm DD/MM/YYYY') : '', 
                                            'source': item.source ? item.source.name : '', 
                                            'staffs': staffName,
                                            'extra_info': item.extra_info
                                        })
                                    })
                                    const data = customerReports.sort((a, b) => order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy])
                                    const worksheet = XLSX.utils.json_to_sheet(data, { origin: 'A2' });
                                    const headers = [
                                        'ID', 
                                        "Họ và tên", 
                                        'Số điện thoại', 
                                        'Tổng chi', 
                                        'Ngày sinh',
                                        'Địa chỉ',
                                        'Email',
                                        'Tương tác gần nhất', 
                                        'Nguồn khách', 
                                        'Nhân sự hỗ trợ',
                                        'Thông tin khác'
                                    ]
                                    worksheet['!merges'] = [
                                        { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length } },
                                    ]
                                    XLSX.utils.sheet_add_aoa(worksheet, [[`Danh sách khách hàng`]], { origin: "A1" });
                                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A2" });
                                    const workbook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                                    XLSX.writeFile(workbook, `Danh sách khách hàng.xlsx`);

                                    toast.dismiss(toastId.current)
                                    toast.success('Tải về thành công')
                                }
                            } catch (error) {
                                toast.dismiss(toastId.current)
                                toast.error('Lỗi tải về')
                            }
                        })
                        .catch((error) => {
                            console.log({ error })
                            toast.dismiss(toastId.current)
                            toast.error('Lỗi tải về')
                        })
                }, 500);
            }, () => { })
    }

    const onAddContact = (customer) => {
        let body = {
            username: customer.username,
            full_name: customer.full_name,
            password1: 'anhdior2023',
            password2: 'anhdior2023'
        }
        if(customer.email.length > 0) body['email'] = customer.email
        if(customer.address.length > 0) body['address'] = customer.address
        if(customer.extra_info.length > 0) body['extra_info'] = customer.extra_info
        axios.post('/rest-auth/registration/', body)
        .then((res) => {
            toast.success('Tạo tài khoản thành công. Chọn bộ lọc tất cả tài khoản để xem được tài khoản mới.')
            setShowAddContactModal(false)
        })
        .catch((error) => {
            console.log({error})
            if(error && error.response && error.response.data) {
                let errors = []
                const keys = Object.keys(error.response.data)
                keys.forEach((key) => {
                    const current = error.response.data[key]
                    console.log({current, key, data: error.response.data})
                    errors = [...errors, ...error.response.data[key]]
                })

                toast.error(errors.join(', '))
            }
        })
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))

    if(!(authUser && hasPermission(authUser, ['view_customer', 'view_customer_all']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }} >
            <ActionsBar title='Danh sách khách hàng' subTitle={`Tìm thấy ${total} khách hàng`} addButtonTitle='Thêm tài khoản' onAddPress={() => setShowAddContactModal(true)} />
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Stack gap={'12px'} sx={{ marginLeft: '16px' }}>
                    <FormControl variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Tên khách hàng, số điện thoại</InputLabel>
                        <Input
                            value={keyword} onChange={handleChangeKeyword}
                            size='small' style={{ width: '300px' }}
                            placeholder="Tên khách hàng, số điện thoại"
                        />
                    </FormControl>
                </Stack>
                {loading && <CircularProgress />}
                <Stack direction='row' sx={{ marginRight: '16px', gap: '16px', fontSize: '12px' }}>
                    <Select value={hasOrder} onChange={(e) => setHasOrder(e.target.value)} variant='standard' sx={{fontSize: '12px'}}>
                        <MenuItem value={1}>Khách đã mua liệu trình</MenuItem>
                        <MenuItem value={0}>Tất cả tài khoản</MenuItem>
                    </Select>
                    {
                        hasPermission(authUser, ['view_customer_all']) && (
                            <Stack sx={{ width: '250px', zIndex: 20 }}>
                                    <MultiSelect
                                        options={staffOptions}
                                        value={selectedStaffs}
                                        onChange={setSelectedStaffs}
                                        labelledBy="Chọn nhân viên"
                                        overrideStrings={{
                                            "allItemsAreSelected": "Tất cả",
                                            "clearSearch": "Xoá",
                                            "clearSelected": "Bỏ chọn",
                                            "search": "Tìm kiếm",
                                            "selectAll": "Chọn tất cả",
                                            "selectAllFiltered": "Chọn tất cả",
                                            "selectSomeItems": "Lựa chọn nhân viên"
                                        }}
                                    />
                                </Stack>
                        )
                    }
                </Stack>
            </Box>
            <div style={{ display: "flex", width: 'calc(100vw - 230px)', marginTop: '24px', padding: '16px' }}>
                <Paper style={{ padding: '16px' }}>
                    <Stack direction='row' justifyContent='flex-end' spacing='16px'>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40, 50]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {hasPermission(authUser, ['export_report_customer']) && <Button onClick={onDownload}><Download /></Button>}
                    </Stack>
                    <TableContainer component={Paper} style={{ overflowY: 'scroll', width: 'calc(100vw - 250px)', overflowX: 'scroll', maxHeight: '60vh' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="data table" stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={total}
                            />
                            <TableBody>
                                {customers.map((customer) => (
                                    <ContactItem customer={customer} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            <AddContactModal 
                open={showAddContactModal}
                handleClose={() => setShowAddContactModal(false)}
                handleAdd={onAddContact}
            />
        </div>
    )
}

export default Screen