import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';
import { LEAD_ORDER_STATUS, convertMoney, getOrderId } from '../../../../utils/utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const OrderItem = ({ order, changeComission }) => {
  const history = useHistory()

  const openDetail = () => {
    history.push(`/crm/lead/${order.user.username}/${order.lead}`)
  }

  let totalPrice = 0
  let totalPaid = 0
  const serviceName = order.orders.map((item) => item.service.name).join(', ')
  let staffs = []
  if(order.sale1) staffs.push(order.sale1.full_name)
  if (order.sale2) staffs.push(order.sale2.full_name)
  if (order.telesale) staffs.push(order.telesale.full_name)
  for (const orderItem of order.orders) {
    totalPrice += orderItem.price
  }
  for (const paymentItem of order.payments ?? []) {
    totalPaid += paymentItem.amount
  }

  // let comissionView = null
  // if (order.comission && (order.comission.sale1 || order.comission.sale1 || order.comission.telesale)) {
  //   comissionView = (
  //     <Stack sx={{ gap: '3px', alignItems: 'flex-end' }}>
  //       <Typography sx={{ fontSize: '11px' }}>{`${convertMoney(order.comission.sale1, true)} / ${convertMoney(order.comission.sale2, true)} / ${convertMoney(order.comission.telesale, true)}`}</Typography>
  //       <Button onClick={() => changeComission && changeComission()} variant='text' sx={{  fontSize: '10px', textTransform: 'none' }}>Chi tiết</Button>
  //     </Stack>
  //   )
  // } else {
  //   comissionView = (
  //     <Button onClick={() => changeComission && changeComission()} variant='contained' sx={{  fontSize: '10px', textTransform: 'none' }}>Thêm</Button>
  //   )
  // }

  console.log({order})
  return (
    <StyledTableRow
      key={order.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell onClick={openDetail} align="right" style={{ cursor: 'pointer', color: 'blue', textDecorationLine: 'underline' }}>{getOrderId(order.id)}</TableCell>
      <TableCell>{dayjs(order.order_date).format('HH:mm DD/MM/YYYY')}</TableCell>
      <TableCell align="right">{`${order.user.full_name}`}<br /><a href={`/crm/contact/${order.user.username}`}>{order.user.username}</a></TableCell>
      <TableCell align="right">{serviceName}</TableCell>
      <TableCell align="right">{staffs.join(', ')}</TableCell>
      <TableCell align="right">{convertMoney(totalPrice)}</TableCell>
      <TableCell align="right">{convertMoney(totalPaid)}</TableCell>
      <TableCell align="right">{order.shop ? order.shop.name : ''}</TableCell>
      <TableCell align="right">{LEAD_ORDER_STATUS[order.status]}</TableCell>
      <TableCell align="right">{order.note}</TableCell>
    </StyledTableRow>
  )
}

export default OrderItem