import { Autocomplete, Badge, Box, Button, Divider, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ActionsBar from '../../../components/ActionsBar';
import TextField from '@mui/material/TextField/TextField';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import { hasPermission } from '../../../utils/utils';
import OrderTab from './tabs/OrderTab';
import WalletTab from './tabs/WalletTab';
import BookingTab from './tabs/BookingTab';
import LeadTab from './tabs/LeadTab';
import VoucherTab from './tabs/VoucherTab';
import ProductTab from './tabs/ProductTab';

const steps = [
    'Chưa có tiến trình',
    'Telesale',
    'Khách quan tâm',
    'Đã đặt lịch tư vấn',
    'Khách mua liệu trình',
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Screen = () => {
    const { username: lead_username } = useParams()
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()

    const [customer, setCustomer] = useState({})
    const [source, setSource] = useState(null)
    const [shop, setShop] = useState(null)
    const [support, setSupport] = useState(null)
    const [selectedTab, setSelectedTab] = React.useState(0);

    const getSupport = useCallback(() => {
        if (token)
            axios.post('/accounts/users/get_support_info/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setSupport(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [lead_username, token])

    const getSource = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_lead_sources/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setSource(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [lead_username, token])

    const getCustomerDetail = useCallback(() => {
        if (token)
            axios.post('/accounts/users/get_info/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setCustomer(res.data)
                        setShop(res.data.working_place)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message)
                        toast.error(error.response.data.message)
                    else
                        toast.error('Không tìm thấy thông tin lead')
                })
    }, [lead_username, token])

    useEffect(() => {
        getCustomerDetail()
        getSource()
        getSupport()
    }, [getCustomerDetail, getSource, getSupport])

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSave = () => {
        if (customer && customer.id) {
            axios.post('/accounts/users/update_info/', {
                user_id: customer.id,
                extra_info: customer.extra_info,
                address: customer.address,
            },)
                .then((res) => {
                    if (res && res.data) {
                        getCustomerDetail()
                        toast.success('Lưu thông tin thành công')
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onChangeShop = (e) => {
        if (customer && customer.id) {
            axios.post('/accounts/users/update_info/', {
                user_id: customer.id,
                working_place: e.target.value
            },)
                .then((res) => {
                    if (res && res.data) {
                        getCustomerDetail()
                        toast.success('Lưu thông tin thành công')
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onChangeSource = (e) => {
        if (customer && customer.id) {
            axios.post('/accounts/leads/update_lead_sources/', {
                user_id: customer.id,
                source_id: e.target.value
            })
                .then((data) => {
                    getSource()
                    toast.success('Lưu thông tin thành công')
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onCreateNewLead = () => {

        axios.post('/accounts/leads/add_customer_lead/', {
            username: lead_username
        })
            .then((res) => {
                if (res && res.data) {
                    history.push(`/crm/lead/${lead_username}/${res.data.id}`)
                } else {
                    toast.error('Tạo lead không thành công')
                }
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Tạo lead không thành công')
            })

    }

    const onChangeSale1 = (e) => {
        if (customer && customer.id) {
            axios.post('/accounts/customers/update_support_user/', {
                user_id: customer.id,
                sale1: e.target.value
            })
                .then((data) => {
                    getSupport()
                    toast.success('Lưu thông tin thành công')
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onChangeSale2 = (e) => {
        if (customer && customer.id) {
            axios.post('/accounts/customers/update_support_user/', {
                user_id: customer.id,
                sale2: e.target.value
            })
                .then((data) => {
                    getSupport()
                    toast.success('Lưu thông tin thành công')
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onChangeTelesale = (e) => {
        if (customer && customer.id) {
            axios.post('/accounts/customers/update_support_user/', {
                user_id: customer.id,
                telesale: e.target.value
            })
                .then((data) => {
                    getSupport()
                    toast.success('Lưu thông tin thành công')
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const updateUsername = () => {
        if (customer && customer.id) {
            axios.post('/accounts/users/update_phone/', {
                user_id: customer.id,
                phone: customer.username
            })
                .then((data) => {
                    history.replace(`/crm/contact/${customer.username}`)
                    toast.success('Lưu thông tin thành công')
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const optionStaffs = [{ id: -1, full_name: 'Xoá sale' }, ...(staffs ?? [])]

    if (!(auth && hasPermission(auth, ['view_customer', 'view_customer_all']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <Stack>
            <ActionsBar title={`Thông tin khách hàng - ${lead_username} - ${customer ? customer.full_name : ''}`} />

            <Stack sx={{ flex: 1 }}>
                <Tabs value={selectedTab} variant="scrollable" onChange={handleChangeTab}
                    scrollButtons="auto" style={{ width: '98%' }}>
                    <Tab value={0} label={`Thông tin khách hàng`} style={{ fontSize: '12px' }} />

                    <Tab value={1} label={`Lead`} style={{ fontSize: '12px' }} />

                    <Tab value={2} label={`Đơn hàng`} style={{ fontSize: '12px' }} />
                    <Tab value={3} label={`Lịch đặt`} style={{ fontSize: '12px' }} />
                    <Tab value={4} label={`Ví Anhdior`} style={{ fontSize: '12px' }} />
                    <Tab value={5} label={`Sản phẩm`} style={{ fontSize: '12px' }} />
                    <Tab value={6} label={`Quà tặng`} style={{ fontSize: '12px' }} />
                </Tabs>
                <Stack sx={{ flex: 1, background: 'white', overflowX: 'scroll', maxHeight: '70vh' }}>
                    <CustomTabPanel value={selectedTab} index={0}>
                        <Stack direction='row' spacing={3} sx={{ padding: '5px', width: '100%' }}>
                            <Stack style={{ background: 'white', borderRadius: 4, flex: 1 }}>
                                <Stack style={{ margin: '16px' }} spacing={2}>
                                    <h4>Thông tin cơ bản</h4>
                                    <Stack direction='row'>
                                        <Input
                                            label="Số điện thoại"
                                            variant="standard" placeholder='Số điện thoại'
                                            value={customer ? customer.username : ''}
                                            onChange={(e) => setCustomer((old) => ({ ...old, username: e.target.value }))}
                                            disabled={!(hasPermission(auth, ['management_access']))}
                                            style={{flex: 1}}
                                        />
                                        {lead_username !== customer.username && hasPermission(auth, ['management_access']) &&
                                            <Button onClick={updateUsername}>Cập nhật</Button>
                                        }
                                    </Stack>

                                    <Input
                                        label="Tên khách hàng"
                                        variant="standard" placeholder='Tên khách hàng' value={customer ? customer.full_name : ''}
                                        onChange={(e) => setCustomer((old) => ({ ...old, full_name: e.target.value }))}
                                        disabled={!(hasPermission(auth, ['view_customer', 'view_customer_all']))}
                                    />

                                    <TextField
                                        label="Địa chỉ"
                                        variant="standard" placeholder='Địa chỉ' value={customer ? customer.address ?? '' : ''}
                                        onChange={(e) => setCustomer((old) => ({ ...old, address: e.target.value }))}
                                    />

                                    <TextField
                                        id="outlined-textarea"
                                        variant="standard"
                                        label="Ghi chú"
                                        multiline
                                        rows={4}
                                        value={customer ? customer.extra_info ?? '' : ''}
                                        onChange={(e) => setCustomer((old) => ({ ...old, extra_info: e.target.value }))}
                                    />
                                </Stack>

                                <Button onClick={handleSave} variant='contained'>
                                    Lưu thông tin
                                </Button>
                            </Stack>

                            <Stack style={{ background: 'white', borderRadius: 4, flex: 1 }}>
                                <Stack style={{ margin: '16px' }} spacing={2}>
                                    <h4>Thông tin sale</h4>
                                    <Stack spacing={1} direction='row' alignItems='center'>
                                        <InputLabel sx={{ width: '120px' }} id="source-select-label">Cửa hàng</InputLabel>
                                        <Select
                                            labelId="shop-select-label"
                                            id="shop-select-select"
                                            value={shop}
                                            label="Cửa hàng"
                                            onChange={onChangeShop}
                                            sx={{ flex: 1 }}
                                        >
                                            {(shops ?? []).map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>

                                    <Stack spacing={1} direction='row' alignItems='center'>
                                        <InputLabel sx={{ width: '120px' }} id="source-select-label">Sale chính</InputLabel>
                                        <Select
                                            labelId="sale1-select-label"
                                            id="sale1-select-select"
                                            value={support && support.sale1 ? support.sale1.id : null}
                                            label="Sale chính"
                                            disabled={!(hasPermission(auth, ['view_customer', 'view_customer_all']))}
                                            onChange={onChangeSale1}
                                            sx={{ flex: 1 }}
                                        >
                                            {(optionStaffs ?? []).map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>

                                    <Stack spacing={1} direction='row' alignItems='center'>
                                        <InputLabel sx={{ width: '120px' }} id="source-select-label">Sale phụ</InputLabel>
                                        <Select
                                            labelId="sale1-select-label"
                                            id="sale1-select-select"
                                            value={support && support.sale2 ? support.sale2.id : null}
                                            label="Sale chính"
                                            disabled={!(hasPermission(auth, ['view_customer', 'view_customer_all']))}
                                            onChange={onChangeSale2}
                                            sx={{ flex: 1 }}
                                        >
                                            {(optionStaffs ?? []).map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>


                                    <Stack spacing={1} direction='row' alignItems='center'>
                                        <InputLabel sx={{ width: '120px' }} id="source-select-label">Telesale</InputLabel>
                                        <Select
                                            labelId="sale1-select-label"
                                            id="sale1-select-select"
                                            value={support && support.telesale ? support.telesale.id : null}
                                            label="Sale chính"
                                            disabled={!(hasPermission(auth, ['view_customer', 'view_customer_all']))}
                                            onChange={onChangeTelesale}
                                            sx={{ flex: 1 }}
                                        >
                                            {(optionStaffs ?? []).map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>

                                    <Stack spacing={1} direction='row' alignItems='center'>
                                        <InputLabel sx={{ width: '120px' }} id="source-select-label">Nguồn khách</InputLabel>
                                        <Select
                                            labelId="source-select-label"
                                            id="source-select"
                                            value={source ? source.id : null}
                                            label="Nguồn khách"
                                            onChange={onChangeSource}
                                            sx={{ flex: 1 }}
                                        >
                                            {(sources ?? []).map((item) => (
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={1}>
                        <LeadTab username={lead_username} />
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={2}>
                        <OrderTab username={lead_username} />
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={3}>
                        <BookingTab username={lead_username} />
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={4}>
                        <WalletTab username={lead_username} />
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={5}>
                        <ProductTab username={lead_username} />
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={6}>
                        <VoucherTab username={lead_username} />
                    </CustomTabPanel>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Screen